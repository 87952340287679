.products {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    background-color: #f3f3f3;
}

.product-item {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product-item img {
    width: 275px;
}

.product-item .description {
    text-align: center; 
}

.product-item:hover {
    transition: 0.3s ease-in;
    cursor: pointer;
  }

  .btn {
    background-color: #febd69;
    border: 2px ;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
  }
  
  .btn:hover {
    background-color: rgb(19, 19, 19);
    color: white;
    cursor: pointer;
  }


