  
  .searchItem {
    width: 700px;
    height: 250px;
    display: flex;
  
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin-top: 60px;
    margin: 30px;
    background-color: #f3f3f3;
  }
  
  .searchItem img {
    width: 200px;
  }
  
  .searchItem .searchItemDescription {
    width: 100%;
    font-size: 30px;
  }
  
  .searchHandler input {
    width: 40px;
    text-align: center;
    font-weight: bolder;
  }

  .paymentBtn {
    background-color: #febd69;
    border: 2px ;
    min-width: 150px;
    padding: 10px 20px;;
    font-size: 15px;
    border-radius: 15px;
  }
  
  .paymentBtn:hover {
    background-color: rgb(19, 19, 19);
    color: white;
    cursor: pointer;
  }
  
  