/* Navbar container */
.navbar {
  display: flex;
  align-items: center;
  background-color: #131921;
  color: white;
  padding: 10px 10px;
}

/* Amazon logo */
.navbarLogo {
  height: 50px;
  width: 150px;
  object-fit: contain;
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transform transition */
}

.navbarLogo:hover {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);  /* subtle shadow */
  transform: scale(1.05);  /* slight scaling */
}

/* Search bar container */
.navbarSearch {
  display: flex;
  align-items: center;
  border: 1px solid #5e6d77;
  border-radius: 4px;
  padding: 5px;
  margin-left: 10px;
  background-color: white;
  justify-content: center;
  flex: 1;
}

/* Search input field */
.navbarSearchInput {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 18px;
}

/* Search button */
.navbarSearchButton {
  background-color: #febd69;
  border: none;
  padding: 5px 10px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  color: #fff;
  transition: color 0.3s;
}

.navbarSearchButton:hover {
  color: #000;
}

/* Sign In and Hello, Guest */
.navbarSignIn {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.navbarSignIn strong {
  font-size: 14px;
  margin-right: 10px;
}

/* Sign-in and Sign-out buttons */
.bt {
  background-color: #febd69;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.bt:hover {
  background-color: #f7a048;
}

/* Orders and Returns links */
.navbarOrders a {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 12px;
  color: white;
  text-decoration: none;
  transition: border-bottom 0.3s ease-in-out;
  border-bottom: 2px solid transparent;
}

.navbarOrders a:hover {
  border-bottom: 2px solid #febd69;
}

/* Shopping cart icon */
/* Shopping cart icon */
.navbarCart a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.navbarCart a:hover svg {
  fill: #febd69;
}

