/* Address.css */

.address {
    text-align: center;
    margin: 10px;
    background-color: #f3f3f3;
  }
  
  .addressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #ff9900;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #e68a00;
  }
  
  /* Style for the country dropdown (assuming it's generated by the Countries component) */
  /* You should adjust this style based on how the Countries component generates the dropdown */
  
  /* Assuming the dropdown is a select element with the id "countries" */
  #countries {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Style for the country dropdown options */
  #countries option {
    padding: 10px;
  }
  
  /* Assuming you want to style the address inputs differently */
  /* #flat {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } */
  

 
  
  /* Assuming you want to style the phone number input differently */
  #contactNumber {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
/* Address.css */

/* ... (previous CSS rules) ... */

/* Style for the Countries dropdown itself */
/* Address.css */

/* ... (previous CSS rules) ... */

/* Style for the Countries dropdown itself */
.select-container {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
  }
.select-container .react-select__control {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;} /* You can adjust the font size */
  
  
  .select-container .react-select__indicator-separator {
    display: none; }/* Remove the vertical line between indicator and dropdown arrow */
  
  
  .select-container .react-select__indicator {
    color: #999; }
  
  
  /* ... (rest of the CSS rules) ... */
  
  
  
  
  /* Additional styling for responsiveness */
  @media (max-width: 600px) {
    .addressContainer {
      max-width: 90%;
    }
  }
  